// THEME PROVIDER
import ThemeProvider from "@/theme/theme-provider";
// PRODUCT CART PROVIDER

import CartProvider from "@/contexts/CartContext";
// SITE SETTINGS PROVIDER

import SettingsProvider from "@/contexts/SettingContext";
// GLOBAL CUSTOM COMPONENTS

import ProgressBar from "@/components/progress";

export default ({children}) => {
    return (
        // <CartProvider>
        //     <SettingsProvider>
        <ThemeProvider>
             {/*<ProgressBar/>*/}
            <div>{children}</div>
        </ThemeProvider>
        //     </SettingsProvider>
        // </CartProvider>
    )

}


