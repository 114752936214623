// CUSTOM COMPONENTS

import { H5 } from "@/components/Typography";
import FlexRowCenter from "@/components/flex-box/flex-row-center";
// IMPORT IMAGES

import logo from "../../../../../../public/storage/assets/images/logo-sm.png";
export default function LogoWithTitle() {
  return <FlexRowCenter flexDirection="column" gap={1.5} mb={4}>
      <img src={logo} alt="bazaar" style={{maxWidth: '12rem'}} />
      <H5 fontWeight={700}>Welcome To Infinimall</H5>
    </FlexRowCenter>;
}
