"use client";

import Button from "@mui/material/Button";

import {useFormik} from "formik";
import * as yup from "yup";
// LOCAL CUSTOM COMPONENTS
import GoogleIcon from '@mui/icons-material/Google';
import EyeToggleButton from "../components/eye-toggle-button";
// LOCAL CUSTOM HOOK

import usePasswordVisible from "../use-password-visible";
// GLOBAL CUSTOM COMPONENTS

import BazaarTextField from "@/components/BazaarTextField";
import {router} from "@inertiajs/react";
import {FlexBox} from "@/components/flex-box/index.js";
// ==============================================================


// ==============================================================
const LoginPageView = ({
                           closeDialog
                       }) => {
    const {
        visiblePassword,
        togglePasswordVisible
    } = usePasswordVisible();
// LOGIN FORM FIELDS INITIAL VALUES

    const initialValues = {
        emailOrPhone: "",
        password: ""
    };
// LOGIN FORM FIELD VALIDATION SCHEMA

    const validationSchema = yup.object().shape({
        password: yup.string().required("Password is required"),
        emailOrPhone: yup
            .string()
            .required("Email or phone number is required")
            .test(
                "email-or-phone",
                "Enter a valid email or phone number",
                function (value) {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    const phoneRegex = /^[0-9]{10,15}$/; // adjust this for your phone number format
                    return emailRegex.test(value) || phoneRegex.test(value);
                }
            )
    });
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, helpers) => {
            console.log(values);
            router.post(route('login'), values, {
                onSuccess: () => closeDialog?.(),
                onError: errors => {
                    console.log(errors);
                    helpers.setErrors(errors);
                },
            });
        }
    });

    return <form onSubmit={handleSubmit}>
        <BazaarTextField
            mb={1.5}
            fullWidth
            name="emailOrPhone"
            size="small"
            variant="outlined"
            onBlur={handleBlur}
            value={values.emailOrPhone}
            onChange={handleChange}
            label="Email or Phone Number"
            placeholder="example@mail.com or 0123456789"
            helperText={touched.emailOrPhone && errors.emailOrPhone}
            error={Boolean(touched.emailOrPhone && errors.emailOrPhone)}
        />

        <BazaarTextField mb={2} fullWidth size="small" name="password" label="Password" autoComplete="on"
                         variant="outlined" onBlur={handleBlur} onChange={handleChange} value={values.password}
                         placeholder="*********" type={visiblePassword ? "text" : "password"}
                         helperText={touched.password && errors.password}
                         error={Boolean(touched.password && errors.password)} InputProps={{
            endAdornment: <EyeToggleButton show={visiblePassword} click={togglePasswordVisible}/>
        }}/>

        <FlexBox justifyContent="space-between" alignItems="center" flexDirection="column" gap={1}>
            <Button fullWidth type="submit" color="primary" variant="contained" size="large">
                Login
            </Button>
            <a href={route('google.redirect')} style={{display: 'block', width: '100%', textDecoration: 'none'}}>
                <Button fullWidth color="primary" variant="outlined" size="large">
                    Login with <GoogleIcon sx={{ml: 1}}/>
                </Button>
            </a>
        </FlexBox>
    </form>;
};

export default LoginPageView;
