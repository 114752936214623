import { Head } from '@inertiajs/react';
import {LoginPageView} from "@/Pages/pages-sections/sessions/page-view/index.js";
import {Wrapper} from "@/Pages/pages-sections/sessions/styles/index.js";
import LogoWithTitle from "@/Pages/pages-sections/sessions/components/logo-title.jsx";
import LoginBottom from "@/Pages/pages-sections/sessions/components/login-bottom.jsx";
import {FlexRowCenter} from "@/components/flex-box/index.js";
import AuthLayout from "@/Layouts/AuthLayout.jsx";

export default function Login({ canResetPassword, disableRegistration }) {
    return (
        <>
            <Head title="Login"/>
            <AuthLayout>
                <FlexRowCenter flexDirection="column" minHeight="100vh" px={2}>
                    <Wrapper elevation={3}>
                        {
                            /* LOGO WITH TITLE AREA */
                        }
                        <LogoWithTitle />

                        {
                            /* FORM AREA */
                        }
                        <LoginPageView/>

                        {
                            /* SOCIAL BUTTON AREA */
                        }
                        {/*<SocialButtons />*/}

                        <LoginBottom canResetPassword={canResetPassword} disableRegistration={disableRegistration}/>
                    </Wrapper>
                </FlexRowCenter>;
            </AuthLayout>
        </>
    );
}
