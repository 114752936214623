"use client";

import { createContext, useMemo, useReducer, useEffect } from "react";

const INITIAL_CART = [];
const INITIAL_STATE = {
    cart: INITIAL_CART,
};

export const CartContext = createContext({});

const reducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_CART_AMOUNT":
            let cartList = state.cart;
            let cartItem = action.payload;
            let exist = cartList.find((item) => item.SKU === cartItem.SKU);

            if (cartItem.qty < 1) {
                const filteredCart = cartList.filter(
                    (item) => item.SKU !== cartItem.SKU,
                );
                return { ...state, cart: filteredCart };
            }

            if (exist) {
                const newCart = cartList.map((item) =>
                    item.SKU === cartItem.SKU
                        ? { ...item, qty: cartItem.qty }
                        : item,
                );
                return { ...state, cart: newCart };
            }

            return {
                ...state,
                cart: [...cartList, { ...cartItem, confirmCheckout: false }],
            };

        case "SET_CUSTOM_MESSAGE":
            const { shopId, customMessage } = action.payload;
            const customMessageUpdatedCart = state.cart.map((item) =>
                item.shop.id === shopId
                    ? { ...item, customMessage: customMessage }
                    : item,
            );
            return {...state, cart: customMessageUpdatedCart}; 

        case "SET_CONFIRM_CHECKOUT":
            const { SKU, confirmed } = action.payload;
            const updatedCart = state.cart.map((item) =>
                item.SKU === SKU
                    ? { ...item, confirmCheckout: confirmed }
                    : item,
            );
            return { ...state, cart: updatedCart };

        case "SET_CONFIRM_CHECKOUT_BULK":
            const { itemsInsideShop, bulkConfirmed } = action.payload;
            const updatedBulkConfirmedCart = state.cart.map((item) => {
                const updatedItem = itemsInsideShop.find(
                    (newItem) =>
                        newItem.id === item.id && newItem.SKU === item.SKU,
                );

                if (updatedItem) {
                    return {
                        ...item,
                        confirmCheckout: bulkConfirmed,
                    };
                }
                return item;
            });

            return {
                ...state,
                cart: updatedBulkConfirmedCart,
            };

        case "UPDATE_VARIANT":
            const updatedItem = action.payload;
            const existingItemIndex = state.cart.findIndex(
                (item) =>
                    item.id === updatedItem.id &&
                    JSON.stringify(item.variantObject) ===
                        JSON.stringify(updatedItem.variantObject),
            );

            if (existingItemIndex !== -1) {
                const updatedCart = state.cart.map((item, index) =>
                    index === existingItemIndex
                        ? { ...item, qty: item.qty + updatedItem.qty }
                        : item,
                );

                return {
                    ...state,
                    cart: updatedCart.filter(
                        (item) =>
                            !(
                                item.SKU === updatedItem.SKU &&
                                item.variantObject !== updatedItem.variantObject
                            ),
                    ),
                };
            } else {
                const updatedCart = state.cart.map((item) => {
                    if (item.SKU === null && item.slug === updatedItem.slug) {
                        return { ...item, ...updatedItem };
                    } else if (item.SKU === updatedItem.SKU) {
                        return { ...item, ...updatedItem };
                    }
                    return item;
                });

                return {
                    ...state,
                    cart: updatedCart.filter(
                        (item) =>
                            !(
                                item.SKU === updatedItem.SKU &&
                                item.variantObject !== updatedItem.variantObject
                            ),
                    ),
                };
            }

        case "POPULATE_CART":
            return {
                ...state,
                cart: action.payload,
            };

        default:
            return state;
    }
};

export default function CartProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE, (initial) => {
        const savedCart = localStorage.getItem("cart");
        return savedCart ? { cart: JSON.parse(savedCart) } : initial;
    });

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(state.cart));
    }, [state.cart]);

    const contextValue = useMemo(
        () => ({ state, dispatch }),
        [state, dispatch],
    );

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
}
