import './bootstrap';
import '../css/app.css';
import { Inertia } from '@inertiajs/inertia';

import {createRoot} from 'react-dom/client';
import {createInertiaApp} from '@inertiajs/react';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import CartProvider from "@/contexts/CartContext.jsx";
import SettingsProvider from "@/contexts/SettingContext.jsx";
import ProgressBar from "@/components/progress/index.js";
import CSRCartProvider from "@/contexts/CSRCartContext.jsx";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

Inertia.on('navigate', (event) => {
    const preserveScroll = event.detail.preserveScroll; // Check if scroll preservation is enabled
    if (!preserveScroll) {
        window.scrollTo(0, 0); // Default: scroll to top
    }
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({el, App, props}) {
        const root = createRoot(el);
        root.render(
            <CartProvider>
                <SettingsProvider>
                    <CSRCartProvider>
                        <ProgressBar/>
                        <App {...props} />
                    </CSRCartProvider>
                </SettingsProvider>
            </CartProvider>
        );
    },
    progress: {
        color: '#4B5563',
    },
});
